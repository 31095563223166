import $ from 'jquery';
$(function ($) {
	// Some random colors
	const colors = ['#3da735', '#127ec2', '#ef7d01', '#e62385', '#9e2f88'];
	const numBalls = 50;
	const balls = [];
	for (let i = 0; i < numBalls; i++) {
		let ballContainer = document.getElementById('ball-container');
		let ball = document.createElement('div');
		ball.classList.add('ball');
		ball.style.background =
			colors[Math.floor(Math.random() * colors.length)];
		ball.style.left = `${Math.floor(Math.random() * 100)}vw`;
		ball.style.top = `${Math.floor(Math.random() * 100)}vh`;
		ball.style.transform = `scale(${Math.random()})`;
		ball.style.width = `${Math.random()}em`;
		ball.style.height = ball.style.width;
		balls.push(ball);
		ballContainer.append(ball);
	}
	// Keyframes
	balls.forEach((el, i) => {
		let to = {
			x: Math.random() * (i % 2 === 0 ? -11 : 11),
			y: Math.random() * 12,
		};
		let anim = el.animate(
			[
				{ transform: 'translate(0, 0)' },
				{ transform: `translate(${to.x}rem, ${to.y}rem)` },
			],
			{
				duration: (Math.random() + 1) * 2000, // random duration
				direction: 'alternate',
				fill: 'both',
				iterations: Infinity,
				easing: 'cubic-bezier(.68,-0.55,.27,1.55)',
			},
		);
	});

	const $currentEventHeaderItem = $('.headerEvents__item.-isSection');

	$('.subNav').addClass('-style-match-' + $currentEventHeaderItem.index());

	$('.eventsSubNav').slideUp(0);

	$(window).on('click', function () {
		$('.modal').removeClass('show-modal');
		$('.subNav:not(.eventsSubNav)').css('z-index', '998');
		$('.pageHeader').css('z-index', '999');
		$('.topHeader').css('z-index', '1000');
	});

	$('.modal__close').on('click', function () {
		$('.modal').removeClass('show-modal');
		$('.subNav:not(.eventsSubNav)').css('z-index', '998');
		$('.pageHeader').css('z-index', '999');
		$('.topHeader').css('z-index', '1000');
	});

	$('.modal__trigger').on('click', function () {
		$(this).siblings().toggleClass('show-modal');
		$('.subNav').css('z-index', '99');
		$('.pageHeader').css('z-index', '99');
		$('.topHeader').css('z-index', '100');
	});

	$('.modal__trigger, .modal__content').on('click', function (event) {
		event.stopPropagation();
	});

	$('.headerEvents__link').on('click', function (event) {
		if (window.matchMedia('(max-width: 767px)').matches) {
			event.preventDefault();

			$('.headerEvents__link')
				.not(this)
				.closest('.headerEvents__item')
				.find('.subNav')
				.slideUp();

			$('.headerEvents__link')
				.not(this)
				.find('.fa-angle-down')
				.removeClass('-rotated');
			$(this)
				.closest('.headerEvents__item')
				.find('.subNav')
				.slideToggle();
			$(this).find('.fa-angle-down').toggleClass('-rotated');
		}
	});

	function revealLabel(ID) {
		let label = $('#' + ID);
		label.removeClass('-hidden');
	}

	function resetLabels() {
		let labels = $('.socialShares__shareToText');
		labels.addClass('-hidden');
	}

	$('.js-displayText')
		.on('mouseover', function () {
			revealLabel($(this).data('linked-media'));
		})
		.on('mouseout', function () {
			resetLabels();
		});

	$('[data-buttonexplode-inner]').on('mouseenter', function (e) {
		var parentOffset = $(this).offset();

		var relX = e.pageX - parentOffset.left;
		var relY = e.pageY - parentOffset.top;
		$(this)
			.prev('[data-buttonexplode-circle]')
			.css({ left: relX, top: relY });
		$(this).prev('[data-buttonexplode-circle]').removeClass('-desplode');
		$(this).prev('[data-buttonexplode-circle]').addClass('-explode');
	});

	$('[data-buttonexplode-inner]').on('mouseleave', function (e) {
		console.log();
		var parentOffset = $(this).offset();

		var relX = e.pageX - parentOffset.left;
		var relY = e.pageY - parentOffset.top;
		$(this)
			.prev('[data-buttonexplode-circle]')
			.css({ left: relX, top: relY });
		$(this).prev('[data-buttonexplode-circle]').removeClass('-explode');
		$(this).prev('[data-buttonexplode-circle]').addClass('-desplode');
	});
});
